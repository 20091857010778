import React from "react"
import SolutionsLayout from "../../components/SolutionsLayout"
import { Grid } from "@material-ui/core"
import Card from "../../components/Card"

export default function CostOptimization() {
  return (
    <SolutionsLayout>
      <h2 className="heading-2 left">Cost Optimization</h2>
      <p className="paragraph left">
        <strong className="bold-text">
          Adhering to cloud cost optimization design principles helps you build
          and operate cost-aware workloads that achieve business goals while
          minimizing operating costs and maximizing your return on investment.
          <br />
          <br />
          Cost optimization is a continual process of refinement and improvement
          over the lifecycle of a workload and we are present at every step to
          assist you in saving money.
        </strong>
        <br />
        <br />
        A well-architected workload uses the most cost-effective resources,
        which can have a significant and positive economic impact. You also have
        the opportunity to use managed services to reduce costs.
        <br />
        <br />
      </p>
      <h2 className="heading-2 left">Benefits</h2>
      <ul className="list">
        <li>
          <b>Analyze Idle Resources:</b> Identify unused resources automatically
        </li>
        <li>
          <b>Store Strategically:</b> Save money and ensure availability of
          resources
        </li>
        <li>
          <b>Economy of scale:</b> Cloud providers possess an economy of scale
          that your business cannot easily achieve, and they frequently pass the
          savings onto you with recurring price cuts.
        </li>
        <li>
          <b>Identify over-provisioned resources:</b> Identify resources that
          could be downsized automatically
        </li>
        <li>
          <b>Cost analytics:</b> Get detailed spending reports, organized by
          business units or projects.
        </li>
      </ul>
      <h2 className="heading-2 left">What we offer</h2>
      <Grid container alignItems="stretch" className={"w-container"}>
        <Grid item xs={12} lg={6}>
          <Card title="Right Sizing" icon={5}>
            Every project is sized just right, with auto-scaling mechanisms to
            always have just enough resources to serve your needs.
            <br />
            We provide multi-scenario cost projections so that you can forecast
            accurately your engagement.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Consumption model" icon={13}>
            We help you decide on the right consumption model for your projects.
            Whether you're looking at long or no commitment, there's a model to
            help you save money.
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card title="Cloud Cost Analytics" icon={4}>
            Automated assessment of idle and over-provisioned resources.
            Automated spending reports.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Storage" icon={16}>
            Multi-tiers storage strategy, because not all data is created equal.
          </Card>
        </Grid>
      </Grid>

      <br />
      <h2 className="heading-2 left">Why partner with us</h2>
      <ul className="list">
        <li>Years of experience saving companies millions.</li>
        <li>Accurate cloud cost projections for each project</li>
        <li>
          Complete guidance in accordance with your budget and requirements
        </li>
        <li>Data-driven approach</li>
      </ul>
    </SolutionsLayout>
  )
}
